<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1>
          Set Custom Theme/Skin
        </h1>
      </div>
    </el-col>
    </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!themeId"
          title="Custom Theme/Skin Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="themeId"
          title="Custom Theme/Skin Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
     
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition" 
  >
  <el-row :gutter="20">
	<el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6"><el-form-item
		label="Select Theme"
		prop="skin_id"
		>
		<el-select v-model="data.skin_id"  placeholder="Select Theme" >
            <el-option
			v-for="item in themes"
			:key="item.value"
			:value="item.value"
			:label="item.label"
			@click="getTheme(item.value)"
			></el-option>
          </el-select>
		</el-form-item>
	 </el-col>
     </el-row>
	<el-row :gutter="20">
          <el-col
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
          >
            <el-form-item
                label="Custom skin "
                prop="skin_custom"
            >
              <!--TinyMce
                  v-if="loadEditor"
                  v-bind:content="data.skin_custom"
                  @contentChanged="updateTextAreaValue"
              >
              </TinyMce-->
			  <el-input
              v-model="data.skin_custom"
              type="textarea"
			  rows="50"
          />
            </el-form-item>
          </el-col>
        </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item>
          
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            Submit
          </el-button>
          <el-button  v-if="!themeId" @click="resetForm('ruleForm')">
            Reset
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>

 </el-form>
</template>
<script>

import ThemeService from "@/api/theme.service";
import authHeader from '@/api/auth-header';
import UserService from "@/api/user.service";
import TinyMce from "@/components/TinyMce";

export default {
	components: {
      TinyMce
    },
  data() {
	var checkSkinCustom = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Custom Skin.'));
        } else {
          callback();
        }
      };
	  var checkMetatitle = (rule, value, callback) => {        
          callback();
      };
      return {
        showAlertAdded: false,
        labelPosition: 'top',
        themeId: this.$route.params.themeId,  
		themes:[],	
        data: {		  
          skin_id: null,
          skin_custom: null,
        },
		 rules: {          
          skin_custom: [
            { validator: checkSkinCustom, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {
	this.getUserWebsite(); 
	this.getthemes(); 
  }, 
	computed: {
       loadEditor: function () {
        return !this.themeId || (this.themeId && this.data.skin_custom);
       },
  },
   methods: {
       updateTextAreaValue(value) {
        this.data.skin_custom = value;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
			 return ThemeService.setThemeskinCustom(this.data).then(response => {
                this.showAlertAdded=true ;
                return response;
              });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }, 
		getTheme(themeId=null){
        return ThemeService.getFileContentTheme(this.data).then(response => {
				this.data.skin_custom   = response.data.data
          });
      },
	getthemes(){		   
		return ThemeService.getThemesList().then(response => {
			this.themes=response.data.data;
		});
	  },
	getUserWebsite() {
		return ThemeService.getUserWebsite().then(response => { 
		this.themeId			= response.data.data[0].theme_id;
		this.data.skin_id		= response.data.data[0].skin_id;
		if(response.data.data[0].skin_custom){
			this.data.skin_id	= parseInt(response.data.data[0].skin_custom);
		}
		this.getTheme(response.data.data[0].skin_id);
	  });
	}
  }
}
</script>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
.upload-demo{ display: flex;gap: 20px;width: 100%;max-width:100%;}
@media screen and (max-width: 767px) {
.upload-demo{ display: unset; gap: unset;width: unset;max-width:unset;}
}
</style>
